
    .select2-dropdown {
      background-color: white;
      overflow: hidden;
      display: block;
      position: absolute;
      left: -100000px;
      width: 100%;
      z-index: 10;
    }

    .select2-results {
      display: block;
      overflow: hidden;
    }

    .select2-results__options {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .select2-results__option {

      user-select: none;
      -webkit-user-select: none;

      &[aria-selected] {
        cursor: pointer;
      }
      &:hover {
      }
    }

    .select2-container--open .select2-dropdown {
      left: 0;
      @include material-shadow-higher;
    }

    .select2-container--open .select2-dropdown--above {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border: 1px solid $grey;
      border-bottom: none;
    }

    .select2-container--open .select2-dropdown--below {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      border: 1px solid $grey;
      border-top: none;
      z-index: 101;
    }

    .select2-search--dropdown {
      display: block;

      .select2-search__field {
        padding: 4px;
        width: 100%;
        box-sizing: border-box;

        &::-webkit-search-cancel-button {
          -webkit-appearance: none;
        }
      }

      &.select2-search--hide {
        display: none;
      }
    }





    .select2-container--contact-form-selector{
      .select2-dropdown--below{
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          border: none;
      }
      .select2-dropdown--above{
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          border: none;
      }
    }