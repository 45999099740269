// grayscale
$black: #000000;
$darkgrey: #333333;
$grey: #7d7d7d;
$lightgrey: #c8c8c8;
$white: #ffffff;

// background colors
$violet1: #9936bd;
$violet2: #7a2b97;
$violet3: #481a59;

// butio colors
$brand1: #1d1d1b;
$brand2: #82358c;
$butio1: #b588d7;
$butio2: #8c63ab;
$butio3: #553170;
$butio4: #d98ce5;
$butio5: #a655b3;