@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:400,700');

@mixin cabin400 {
  font-family: 'Roboto Mono', sans-serif;
  font-weight: 400;
}
@mixin cabin600 {
  font-family: 'Roboto Mono', sans-serif;
  font-weight: 600;
}
@mixin cabin700 {
  font-family: 'Roboto Mono', sans-serif;
  font-weight: 700;
}