body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  color: white;
  text-align: right;
  @include cabin400;
  a, a:visited, a:hover, a:active {
    text-decoration: none;
  }
  ul, ol {
    padding: 0;
    margin: 0;
  }
  * {
    display: block;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
section {
  width: 100%;
  padding: 30px;
}
section#home {
  height: 80vh;
  @include bg-char(50%,50%,no-repeat,cover);
  background-image: url('../assets/images/background_2.svg');
  #backgroundWrapper {
    @include absolute(0,0,0,0);
    overflow: hidden;
    img#homeBackground {
      position: absolute;
      z-index: 10;
      left: 50%;
      top: 50%;
      @include transform(translate(-50%,-50%) scale(1.5));
      width: 40vw;
    }
    
  }
  img#butioBot {
    position: absolute;
    top: 15vh;
    left: 10vh;
    height: 75vh;
    width: auto;
    z-index: 20;
  }
  h3, h5 {
    color: white;
    margin-right: 30px;
  }
}
section#portfolio {
  max-height: 20vh;
  #logoWrapper {
    height: 10vh;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    a.logo {
      display: block;
      position: absolute;
      @include transform(translate(-50%,-50%));
      top: 50%;
      left: 50%;
      width: 170px;
      min-height: 80px;
      img#logo {
        max-width: 100%;
      }
    }
  }
}